<template>
	<common-list :div-class="['page-component']">
		<section class="mypage-section">
			<header class="section-header">
				<h3 class="section-title">회원탈퇴</h3>
				<p class="section-subtext"></p>
				<h6 class="title" style="color: #ff7300; font-weight: 800">* 유의사항</h6>
				<br />
				<p class="section-subtext" style="font-weight: bold">사용하고 계신 ID는 탈퇴할 경우, 재사용 및 복구가 불가능합니다.</p>
				<br />
				<p class="section-subtext">탈퇴한 ID는 본인과 타인 모두 재사용 및 복구가 불가능하며,</p>
				<br />
				<p class="section-subtext" style="margin-bottom: 12px">탈퇴 후 다른 ID로의 재가입은 가능합니다.</p>
				<br />
				<p class="section-subtext" style="font-weight: bold">회원탈퇴 후, 해당 ID를 통한 하단 서비스는 확인이 불가능합니다.</p>
				<br />
				<p class="section-subtext" style="margin-bottom: 12px">스튜디오 예약내역,지원사업 진행현황, 설문, 상담내역</p>
				<br />
				<p class="section-subtext" style="font-weight: bold">회원탈퇴 후에도 게시판형 서비스에 등록하신 게시물은 남아있습니다.</p>
				<br />
				<p class="section-subtext">댓글, 게시글에 대한 삭제를 원하신다면 반드시 탈퇴 전에 삭제해주세요.</p>
				<br />
				<p class="section-subtext" style="color: #015aa8; font-weight: 800">
					<input v-model="agreeWhdwl" class="form-check-input" type="checkbox" id="privacyAgreeCheck" name="privacyAgreeCheck" />
					회원 탈퇴시 발생하는 유의사항을 확인하였습니다.
				</p>
			</header>

			<form v-if="agreeWhdwl" action="">
				<div class="form-field">
					<div class="form-row form-buttons">
						<loading-button :is-loading="isLoading" button-text="회원탈퇴" :button-classes="['btn-xl', 'btn-primary']" :click-func="clickWhdwl"></loading-button>
					</div>
				</div>
			</form>
			<form v-else action="">
				<div class="form-field">
					<div class="form-row form-buttons">
						<button class="btn btn-xl btn-primary" style="background-color: #f3f5f7" disabled>
							<span class="text" style="color: black">회원탈퇴</span>
						</button>
					</div>
				</div>
			</form>
		</section>

		<ul class="mypage-dm-list"></ul>
	</common-list>
</template>

<script>
import { getItem, getItems, getPaging, getRegisterTimeFormat, getResult, isSuccess, lengthCheck, setPaging, strToHex } from '../../assets/js/utils';
import { mapGetters } from 'vuex';
import { ACT_GET_MESSAGE_LIST, ACT_GET_SESSION, ACT_LOGIN_USER, ACT_REMOVE_AUTH_TOKEN, ACT_USER_WHDWL, ACT_CHECK_SSO_LOGIN_INFO, ACT_AUTH_PWD_CHECK } from '../../store/_act_consts';
import { MUT_SET_RETURN_ROUTE, MUT_SHOW_ALERT, MUT_SHOW_CONFIRM, MUT_SHOW_CONNECT, MUT_SHOW_CONNECT_VIEW, MUT_SHOW_PROMPT } from '../../store/_mut_consts';
import CommonList from '../common/CommonList';
import Base64 from 'crypto-js/enc-base64';
import SHA512 from 'crypto-js/sha512';
import LoadingButton from '../../components/common/LoadingButton';

export default {
	name: 'Whdwl',
	components: {
		CommonList,
		LoadingButton,
	},
	data: () => ({
		isLoading: false,
		hasMore: false,
		items: [],
		agreeWhdwl: false,
	}),
	computed: {
		...mapGetters('auth', ['isAuth', 'session']),
	},
	created() {},
	watch: {},
	methods: {
		async clickWhdwl() {
			if (this.isLoading) return;
			this.isLoading = true;

			await this.$store.commit(`common/${MUT_SHOW_PROMPT}`, {
				title: `비밀번호를 입력해주세요.`,
				showCancelButton: true,
				yesfunc: async (param) => {
					const res = await this.$store.dispatch(`auth/${ACT_GET_SESSION}`);
					this.item = getItem(res);
					const SYS_TYP_CD = '800';
					// this.isLoading = true;
					const LGN_ID = this.item.lgnId;
					// const PASSWD = this.passwd.trim();
					let PASSWD = strToHex(SHA512(param.trim()).toString(Base64));
					const PASSWDHEX = strToHex(param.trim());

					try {
						//const authRes = await this.$store.dispatch(`auth/${ACT_LOGIN_USER}`, { LGN_ID, PASSWD, SYS_TYP_CD });
						const authRes = this.session.userPattnCd == '400' ? await this.$store.dispatch(`auth/${ACT_CHECK_SSO_LOGIN_INFO}`, { ssoId: this.session.lgnId, ssoPw: PASSWDHEX }) : await this.$store.dispatch(`auth/${ACT_AUTH_PWD_CHECK}`, { PASSWD });
						this.lgnFailReason = authRes.result.code;
						this.isLoading = false;
						if (isSuccess(authRes)) {
							//통합회원은 패스워드 체크 패스
							this.$store.commit(`common/${MUT_SHOW_CONFIRM}`, {
								title: `탈퇴하시겠습니까?`,
								showCancelButton: true,
								yesfunc: async () => {
									this.isLoading = true;
									await this.$store.dispatch(`auth/${ACT_USER_WHDWL}`, { lgnId: '' }).then((res) => {
										if (isSuccess(res)) {
											this.$store.dispatch(`auth/${ACT_REMOVE_AUTH_TOKEN}`);
											this.$store.commit(`common/${MUT_SHOW_ALERT}`, {
												title: '탈퇴가 완료되었습니다.',
												yesfunc: () => {
													this.$router.push({ name: 'Main' });
												},
											});
										} else if (res && res.result && res.result.number === 301) {
											this.$store.commit(`common/${MUT_SHOW_ALERT}`, {
												title: '마켓 입점 이력이 있습니다. <br/>고객센터에 문의해주세요. <br/>1899-0309',
												html: true,
											});
										} else if (res && res.result && res.result.number === 302) {
											this.$store.commit(`common/${MUT_SHOW_ALERT}`, {
												title: '관리자 권한이 부여되어 있습니다. <br/>고객센터에 문의해 주세요.<br/>1899-0309',
												html: true,
											});
										} else {
											this.$store.commit(`common/${MUT_SHOW_ALERT}`, {
												title: '통합 탈퇴 시스템 오류',
												html: true,
											});
										}
									});
									this.isLoading = false;
								},
							});
						} else if (this.lgnFailReason === 'LGN_FAIL_TMS') {
							this.$store.commit(`common/${MUT_SHOW_ALERT}`, {
								title: '비밀번호를 5회 잘못 입력하셨습니다. <br/>본인인증 후 비밀번호를 재설정 해주세요.',
								yesfunc: () => {
									this.$store.dispatch(`auth/${ACT_REMOVE_AUTH_TOKEN}`);
									this.$router.push({ name: 'FindInfo', params: { division: 'password' } });
								},
								html: true,
							});
						} else {
							await this.$store.commit(`common/${MUT_SHOW_CONFIRM}`, {
								title: '비밀번호가 잘못되었습니다.',
								confirmButtonText: '다시입력',
								cancelButtonText: '닫기',
								showCancelButton: true,
								yesfunc: () => {
									this.clickWhdwl();
								},
							});
						}
					} catch (e) {
						console.error(e);
						this.viewError();
					}
				},
				nofunc: async () => {
					this.isLoading = false;
				},
			});
		},
	},
};
</script>
