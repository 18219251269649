<template>
  <section class="apply-section section-form">
    <header class="section-header">
      <h3 class="title">입점신청</h3>
    </header>
    <!-- round-tabs -->
    <div class="round-tabs">
      <ul class="tab-list">
        <li class="tab-item">
          <a href="javascript:" class="tab-link">
            <span class="tab-text">입점신청</span>
          </a>
        </li>
        <li class="tab-item is-selected">
          <a href="javascript:" class="tab-link">
            <span class="tab-text">신청정보 수정</span>
          </a>
        </li>
      </ul>
    </div>
    <header class="section-header mt-5"><p class="text">아래와 같은 사유로 입점신청이 반려되었습니다.</p></header>
    <article class="form-section">
      <header class="section-header"><h3 class="title">반려 사유</h3></header>
      <div class="terms-agree-wrapper" style="width: 100%">
        <div class="terms-content">
          <dl class="terms">
            <dd class="description"><p class="text">{{ rjctRsnText }}</p></dd>
          </dl>
        </div>
      </div>
    </article>

    <div class="page-buttons flex-center is-wide">
      <loading-button
          button-text="수정하기"
          :is-loading="false"
          :click-func="clickCheck"
          :button-classes="['btn', 'btn-primary']"
          :text-classes="[]"
          loading-size="sm"
      ></loading-button>
    </div>
  </section>
</template>
<script>
import LoadingButton from '../common/LoadingButton';
import {ACT_CHECK_CMSTR_APLY} from '../../store/_act_consts';
import {getItem, lengthCheck} from '../../assets/js/utils';

export default {
  name: 'StartCheck',
  props: {
    rjctRsn: String,
    mutParams: Function
  },
  components: {
    LoadingButton
  },
  computed: {
    rjctRsnText() {
      return this.rjctRsn ? this.rjctRsn : '-';
    }
  },
  methods: {
    clickCheck() {
      this.mutParams();
    }
  }
};
</script>
