<template>
  <div class="page-component">
    <!-- mypage-section -->
    <section class="mypage-section">
      <header class="section-header">
        <h3 class="section-title">정보수정</h3>
        <p class="section-subtext">정보를 수정 할 수 있습니다.</p>
        <button type="button" class="btn btn-primary btn-xl" @click="profileShowModal">
          <span class="text">수정하기</span>
        </button>
      </header>
<!--      <div>-->
<!--        <div class="form-field">-->
<!--          <div class="form-row">-->
<!--            <div class="form-cellth">-->
<!--              <label>비밀번호 확인</label>-->
<!--            </div>-->
<!--            <div class="form-celltd">-->
<!--              <input-->
<!--                  type="password"-->
<!--                  class="form-control"-->
<!--                  :data-valid="(!passwd ? 'invalid' : '')"-->
<!--                  placeholder="비밀번호 입력"-->
<!--                  v-model="passwd"-->
<!--                  @keyup.enter="profileShowModal"-->
<!--              >-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="form-row form-buttons">-->
<!--            <button type="button" class="btn btn-primary btn-xl" @click="profileShowModal">-->
<!--              <span class="text">수정하기</span>-->
<!--            </button>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
    </section>

    <!-- mypage-section -->
    <section class="mypage-section">
      <header class="section-header">
        <h3 class="section-title">소상공인확인서 올리기</h3>
        <p class="section-subtext">소상공인확인서란 자영업 및 중소기업 등의 사업을 증빙하는 서류입니다.</p>
      </header>
      <form action="">
        <div class="form-field">
          <div class="form-row form-buttons">
            <button type="button" class="btn btn-outline-light btn-xl" @click="clickUpload">
              <span class="text">확인서 업로드</span>
            </button>
            <input ref="idnty" type="file" @change="uploadIdnty" style="display: none">
          </div>
          <div class="form-row form-buttons">
            <button v-if="idntyFileNm" class="btn btn-lg btn-secondary" :disabled="downing" @click="clickDownload">
              <span class="text">{{ '소상공인확인서' }}<br>[{{ idntyFileNm }}]</span>
            </button>
          </div>
        </div>

      </form>
    </section>

    <!-- mypage-section -->
    <section class="mypage-section" v-if="session.userPattnCd === '200' || session.userPattnCd === '300'">
      <header class="section-header">
        <h3 class="section-title">인플루언서 {{creatorText}}</h3>
        <p class="section-subtext" v-if="session.userPattnCd === '200'">입점신청을 하지 않은 소상공인의 경우 크리에이터로 전환할 수 있습니다.</p>
        <p class="section-subtext" v-else-if="session.userPattnCd === '300'">인플루언서 정보를 수정할 수 있습니다.</p>
      </header>
      <form action="">
        <div class="form-field">
          <div class="form-row form-buttons">
            <button type="button" class="btn btn-secondary btn-xl" @click="creatorShowModal">
              <span class="text">인플루언서 {{creatorText}}</span>
            </button>
          </div>
        </div>
      </form>
    </section>
  </div>
</template>
<script>
import {ACT_AUTH_PWD_CHECK, ACT_CHECK_SESSION, ACT_DOWN_ATTACH_PLAYGROUND3, ACT_GET_CTR_APRV_STATUS, ACT_GET_IDENTITY_FILE, ACT_IDENTITY_FILE, ACT_IDENTITY_FILE_DOWNLOAD, ACT_UPLOAD_IDENTITY, ACT_USER_WHDWL} from '../../store/_act_consts';
import {getItem, isSuccess, lengthCheck, strToHex} from '../../assets/js/utils';
import {MUT_SHOW_ALERT, MUT_SHOW_CREATOR_CHANGE_MODAL, MUT_SHOW_PROFILE_MODAL} from '../../store/_mut_consts';
import {mapGetters} from 'vuex';
import SHA512 from 'crypto-js/sha512';
import Base64 from 'crypto-js/enc-base64';

export default {
  name: 'Profile',
  data: ()=>({
    passwd: '',
    ctrAprvStsCd:'',
    idntyFileNm:'',
    downing: false,
  }),
  async created() {
    await this.$store.dispatch(`auth/${ACT_CHECK_SESSION}`);
    this.fileList();
  },
  computed: {
    ...mapGetters('auth', ['isAuth', 'session']),
    creatorText(){
      return this.session.userPattnCd === '300' ? '수정' : '전환';
    }
  },
  methods: {
    clickUpload(){
      this.$refs.idnty.click();
    },
    fileList(){
      this.$store.dispatch(`auth/${ACT_GET_IDENTITY_FILE}`).then((res) => {
        const item = getItem(res);
        this.idntyFileNm = item.idntyFileNm;
      });
    },
    uploadIdnty(){
      if(this.$refs.idnty.files && this.$refs.idnty.files.length > 0){
        this.$store.dispatch(`auth/${ACT_UPLOAD_IDENTITY}`, this.$refs.idnty.files).then(res => {
          if (res.data && isSuccess(res.data)){
            this.$store.commit(`common/${MUT_SHOW_ALERT}`, {title: '소상공인확인서가 업로드 되었습니다.', html: true,
              yesfunc: () => {
                this.$router.go();
              },
            });
          }else{
            this.$store.commit(`common/${MUT_SHOW_ALERT}`, {title: '오류가 발생했습니다. 다시 시도해주세요.'});
          }
          this.$refs.idnty.value = '';
        });
      }
    },
    profileShowModal(){

      this.$store.commit(`auth/${MUT_SHOW_PROFILE_MODAL}`);
      // const PASSWD = strToHex(SHA512(this.passwd.trim()).toString(Base64));
      //
      // if(PASSWD) {
      //   try {
      //     const authRes = await this.$store.dispatch(`auth/${ACT_AUTH_PWD_CHECK}`, {PASSWD});
      //     if(isSuccess(authRes)){
      //       this.$store.commit(`auth/${MUT_SHOW_PROFILE_MODAL}`);
      //
      //       this.passwd = '';
      //     } else {
      //       this.$store.commit(`common/${MUT_SHOW_ALERT}`, {
      //         title: '비밀번호가 틀렸습니다. 다시 확인해주세요.',
      //         yesfunc: () => {this.passwd = ''},
      //       });
      //     }
      //   } catch (e) {
      //     console.error(e);
      //   }
      // } else {
      //   this.$store.commit(`common/${MUT_SHOW_ALERT}`, {title: '비밀번호를 입력해주세요.'});
      // }
    },
    creatorShowModal(){
      this.$store.commit(`auth/${MUT_SHOW_CREATOR_CHANGE_MODAL}`);
    },
    clickDownload() {
      this.downing = true;
      this.$store.dispatch(`auth/${ACT_IDENTITY_FILE_DOWNLOAD}` ,this.idntyFileNm)
    }
  }

}
</script>

