<template>
  <div class="page-component">
    <section class="mypage-section">
      <header class="section-header mb-0">
        <h3 class="section-title">맞춤 인플루언서찾기 등록</h3>
        <p class="section-subtext">맞춤 인플루언서찾기를 통해 다양한 소상공인분들과 함께하며, 마음껏 역량을 뽐내세요.</p>
      </header>
      <div>
        <div class="form-field">
          <div class="form-buttons">
            <button type="button" class="btn btn-primary btn-xl" @click="showFootHoldEdit">
              <span class="text">{{this.action}}하기</span>
            </button>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import {MUT_SHOW_FOOTHOLD} from '../../store/_mut_consts';
import {ACT_GET_CREATOR_MY_FOOTHOLD} from '../../store/_act_consts';
import {getItem, lengthCheck} from '../../assets/js/utils';
export default {
  name: 'Creator',
  data: () => ({
    ctrFthldId: 0,
  }),
  created() {
    this.onReady();
  },
  computed: {
    //20220622 인플루언서 정보가 등록된경우 수정버튼으로 변경 - hib
    action(){
      return this.ctrFthldId > 0 ? '수정' : '등록';
    },
  },
  methods: {
    //20220622 인플루언서 정보가 등록되었는지 확인 - hib
    onReady(){
      this.$store.dispatch(`community/${ACT_GET_CREATOR_MY_FOOTHOLD}`).then(res => {
        if(lengthCheck(res)){
          const item = getItem(res);
          this.ctrFthldId = item.ctrFthldId;
        }
      });
    },
    showFootHoldEdit(){
      this.$store.commit(`community/${MUT_SHOW_FOOTHOLD}`);
    }
  }
}
</script>


