<template>
  <common-list :class="['page-component']"
               :is-loading="isLoading"
               :is-no-result="isNoResult"
  >
    <div class="board-list-container mypage-notice-list">
      <ul class="board-list">
        <li v-for="(item, idx) in items" class="board-list-item" :key="idx">
          <div class="board-list-row">
            <div class="board-list-content">
              <div class="badge">
                <span class="badge-text">정보</span>
              </div>
              <div class="content-title">
                <a href="javascript:" class="title-link" data-anim="true" @click="showModal(item)">
                  <span class="title">{{ item.sprtBizNtcTit }}</span>
                </a>
              </div>
              <div class="content-date">
                <span class="text">{{ registerTimeFormat(item.regDt) }}</span>
              </div>
            </div>

            <div v-if="!isMobile" class="board-list-actions">
              <button class="btn btn-primary" type="button" @click="showModal(item)">
                <span class="text">내용확인</span>
              </button>
            </div>

          </div>
        </li>
      </ul>
      <div v-if="hasMore" class="page-buttons" data-align="center">
        <button class="btn btn-lg btn-outline-white" @click="morePage">
          <span class="text">더보기</span>
        </button>
      </div>
    </div>
  </common-list>
</template>

<script>
import {ACT_CHECK_SESSION, ACT_GET_SUPPORT_NOTICE_LIST} from '../../store/_act_consts';
import {getItems, getRegisterTimeFormat, lengthCheck, setPaging} from '../../assets/js/utils';
import {MUT_SHOW_NOTICE_MODAL} from '../../store/_mut_consts';
import {mapGetters} from 'vuex';
import CommonList from '../common/CommonList';

export default {
  name: 'Notice',
  components: {
    CommonList
  },
  data: () => ({
    isLoading: false,
    hasMore: false,
    totalCount: 0,
    pageSize: 4,
    pageNo: 1,
    items: [],
  }),
  computed: {
    ...mapGetters('common', ['isMobile']),
    isNoResult(){
      return this.items.length === 0;
    }
  },
  created() {
    this.$store.dispatch(`auth/${ACT_CHECK_SESSION}`);
    this.getSupportNoticeList(true);
  },
  methods: {
    getSupportNoticeList(isInit){
      if(isInit) {
        this.items = [];
        this.isLoading = true;
      }
      this.$store.dispatch(`support/${ACT_GET_SUPPORT_NOTICE_LIST}`, {
        pageNo: this.pageNo,
        pageSize: this.pageSize
      }).then(res => {
        if(lengthCheck(res)){
          this.items = this.items.concat(getItems(res));
          setPaging(this, res);
        }else{
          this.hasMore = false;
        }
        this.isLoading = false;
      }).catch(e => {
        console.error(e);
        this.hasMore = false;
        this.isLoading = false;
      });
    },
    registerTimeFormat(timestamp){
      return getRegisterTimeFormat(timestamp);
    },
    morePage(){
      if(this.hasMore) this.pageNo++;
      this.getSupportNoticeList();
    },
    showModal(item){
      this.$store.commit(`support/${MUT_SHOW_NOTICE_MODAL}`, {
        sprtBizNtcTit: item.sprtBizNtcTit,
        sprtBizNtcCn: item.sprtBizNtcCn,
        regDt: item.regDt
      });
    }
  }
};
</script>
