<template>
  <section class="apply-section section-procedure">
    <header class="section-header"><h3 class="title">입점절차</h3></header>
    <div class="apply-guide">
      <ol class="guide-list">
        <li class="guide-item">
          <span class="step">01</span><i class="icon-guide-01"></i>
          <span class="header">입점신청</span>
          <span class="text">가치라이브 사이트 방문하고,<br>하단의 입점신청을 기입하세요</span>
        </li>
        <li class="guide-item" :class="{'gachi-active-border': cmstrAprvStsCd === '100'}">
          <span class="step">02</span><i class="icon-guide-02"></i>
          <span class="header">서류검토</span>
          <span class="text">입점요건과 신청서류 검토 후 <br>결과를 알려드려요</span>
        </li>
        <li class="guide-item">
          <span class="step">03</span><i class="icon-guide-03" :class="{'gachi-active-border': cmstrAprvStsCd === '200'}"></i>
          <span class="header">전자계약</span>
          <span class="text">전자계약 회원 가입 하시고, <br>상호간 전자계약을 진행합니다</span>
        </li>
        <li class="guide-item" :class="{'gachi-active-border': cmstrAprvStsCd === '300'}">
          <span class="step">04</span><i class="icon-guide-04"></i>
          <span class="header">계정발급, 판매</span>
          <span class="text">계정 발급 후 판매자매뉴얼을 확인하면 <br>상품 판매가 가능합니다</span>
        </li>
      </ol>
    </div>
  </section>
</template>
<script>

export default {
  name: 'StartHeader',
  props: {
    cmstrAprvStsCd: String
  }
};
</script>
