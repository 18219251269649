<template>
  <!--  <main class="page-container" id="sme-mypage">-->
  <main class="page-container" :id="myPageId">
    <div class="page-header">
      <div class="page-component">
        <div class="page-header-title">
          <div class="mypage-header">
            <h2 class="title"><span class="name">{{ session.userNm }}</span>님,<br>안녕하세요</h2>
            <div class="header-profile">
              <img v-if="session.profileImg" :src="session.profileImg" alt="">
              <img v-else src="../../../assets/img/community/reply_unknown_icon.svg" alt="">
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="page-body">
      <nav v-if="isMyPagePath" class="page-nav">
        <ul class="nav-list page-component">
          <!-- <template v-for="(myPagePath, idx) in myPagePaths">
            <template v-if="myPagePath.isShow">
              <router-link
                  custom
                  :to="`/mypage/${myPagePath.path}`"
                  v-slot="{ href, navigate, isActive, isExactActive }"
              >
                <li class="nav-item gachi-cursor-pointer" :class="{'is-selected': isActive}" :key="idx" @click="navigate">
                  <a :href="href"><span class="nav-text">{{myPagePath.name}}</span></a>
                </li>
              </router-link>
            </template>
            <template v-else>
              <template v-if="myPagePath.condition === 'creator'">
                <router-link
                    v-if="session.userPattnCd === '300'"
                    custom
                    :to="`/mypage/${myPagePath.path}`"
                    v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <li class="nav-item gachi-cursor-pointer" :class="{'is-selected': isActive}" :key="idx" @click="navigate">
                    <a :href="href"><span class="nav-text">{{myPagePath.name}}</span></a>
                  </li>
                </router-link>
              </template>
              <template v-if="myPagePath.condition === 'supply'">
                
                <router-link
                    v-if="session.userPattnCd === '200' && !session.supp"
                    custom
                    :to="`/mypage/${myPagePath.path}`"
                    v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <li class="nav-item gachi-cursor-pointer" :class="{'is-selected': isActive}" :key="idx" @click="navigate">
                    <a :href="href"><span class="nav-text">{{myPagePath.name}}</span></a>
                  </li>
                </router-link>
              </template>


            </template>
          </template> -->
        </ul>
      </nav>

      <profile v-if="path === 'profile'"></profile>
      <connect v-else-if="path === 'connect'"></connect>
      <creator v-else-if="path === 'creator'"></creator>
      <reservation v-else-if="path === 'reserve'"></reservation>
      <campaign-apply v-else-if="path === 'campaign'"></campaign-apply>
      <apply v-else-if="path === 'apply'"></apply>
      <notice v-else-if="path === 'notice'"></notice>
      <start v-else-if="path === 'start'"></start>
      <survey v-else-if="path === 'survey'"></survey>
      <whdwl v-else-if="path === 'whdwl'"></whdwl>

    </div>
  </main>
</template>
<script>
import {mapGetters} from 'vuex';
import Profile from '../../../components/mypage/Profile';
import Creator from '../../../components/mypage/Creator';
import CampaignApply from '../../../components/mypage/CampaignApply';
import Connect from '../../../components/mypage/Connect';
import Reservation from '../../../components/mypage/Reservation';
import Apply from '../../../components/mypage/Apply';
import Notice from '../../../components/mypage/Notice';
import Start from '../../../components/mypage/Start';
import Survey from '../../../components/mypage/Survey';
import Whdwl from '../../../components/mypage/Whdwl';

export default {
  name: 'MyPage',
  components: {
    Profile,
    Creator,
    CampaignApply,
    Connect,
    Reservation,
    Apply,
    Notice,
    Start,
    Survey,
    Whdwl
  },
  data: () => ({
    path: '',
    myPagePaths: [
      {name: '개인정보', path: 'profile', isShow: true},
      {name: '인플루언서등록', path: 'creator', condition: 'creator'},
      {name: '스튜디오 예약내역', path: 'reserve', isShow: true},
      // {name: '캠페인지원상황', path: 'campaign', condition: 'creator'},
      {name: '지원사업 진행현황', path: 'apply', isShow: true},
      {name: '선정자 공지', path: 'notice', isShow: true},
      {name: '입점신청', path: 'start', condition: 'supply'},
      {name: '설문', path: 'survey', condition: 'survey', isShow: true},
      {name: '상담내역', path: 'connect', isShow: true},
      {name: '회원탈퇴', path: 'whdwl', isShow: true}, // 회원탈퇴 임시주석
    ]
  }),
  computed: {
    ...mapGetters('common', ['isMobile']),
    ...mapGetters('auth', ['isAuth', 'session']),
    isMyPagePath(){
      return this.myPagePaths.map(x => x.path).includes(this.$route.params.path);
    },
    myPageId(){
      return this.isMobile && this.$route.params.path.includes('apply') ? 'sme-mypage-apply' : 'sme-mypage'
    }
  },
  watch: {
    $route(to){
      if(this.myPagePaths.map(x => x.path).includes(to.params.path)) this.path = to.params.path;
    }
  },
  created() {
    if(this.isMyPagePath) this.path = this.$route.params.path;
  },
  methods: {}
}
</script>