<template>
    <common-list
        :div-class="['page-component']"
        :is-loading="isLoading"
        :is-no-result="isNoResult"
    >
    <div class="show-connect-btn">
      <div class="connect-p">
        온라인 진출에 애로사항이 있으시다면,<br>
        저희가 도와드립니다. 아래 상담하기 버튼을 눌러 애로사항을 말씀해주세요.
      </div>
      <button class="btn btn-lg btn-secondary" @click="showConnect">
        <span class="text">상담하기</span>
      </button>
    </div>
      <ul class="mypage-dm-list">
        <li class="mypage-dm-item" v-for="(item, idx) in items" :key="idx">
          <div class="mypage-dm-card">
            <div class="card-image">
              <a href="javascript:;" class="image-link" @click="showConnectView(item, idx)">
                <img v-if="item.imgPtUrl" :src="item.imgPtUrl" alt="">
                <img v-else src="../../assets/img/community/reply_unknown_icon.svg" alt="">
              </a>
            </div>
            <div class="card-content">
              <a href="javascript:" class="sender-link" data-anim="true" @click="showConnectView(item, idx)">
                <span class="text sender" :class="{'gachi-read-text': item.readgYn === 'Y'}">{{ item.nickNm ? item.nickNm : item.userNm }}</span>
                <span class="text text-truncate" :class="{'gachi-unread-text': item.readgYn !== 'Y'}">{{ item.msgCn }}</span>
              </a>
              <span class="dm-date">{{ regDtTimeFormat(item.regDt) }}</span>
            </div>
          </div>
        </li>
      </ul>
      <div v-if="hasMore" class="page-buttons" data-align="center">
        <button class="btn btn-lg btn-outline-white" @click="morePage">
          <span class="text">더보기</span>
        </button>
      </div>
    </common-list>
</template>

<script>
import {getItems, getPaging, getRegisterTimeFormat, lengthCheck, setPaging} from '../../assets/js/utils';
import {mapGetters} from 'vuex';
import {ACT_GET_MESSAGE_LIST} from '../../store/_act_consts';
import {MUT_SHOW_CONNECT, MUT_SHOW_CONNECT_VIEW, MUT_SHOW_ALERT, MUT_SET_RETURN_ROUTE} from '../../store/_mut_consts';
import CommonList from '../common/CommonList';
import {getTokenByKey} from '../../assets/js/jwt.service';

export default {
  name: 'Connect',
  components: {
    CommonList
  },
  data: ()=>({
    isLoading: false,
    hasMore: false,
    totalCount: 0,
    pageSize: 4,
    pageNo: 1,
    items: []
  }),
  computed:{
    ...mapGetters('auth', ['isAuth']),
    isNoResult(){
      return this.items.length === 0;
    }
  },
  created() {    
    this.isLoginCheck();
    this.getMessageList(true);
  },
  methods: {
    isLoginCheck(){
      if (!getTokenByKey('auth_token')) {
        this.$store.commit(`common/${MUT_SHOW_ALERT}`, {
          title: '회원가입 및 로그인이 필요합니다.',
          html: true,
          yesfunc: () => {
            this.$store.commit(`common/${MUT_SET_RETURN_ROUTE}`, this.$route.path);
            this.$router.push({ name: 'Login' });
          },
        });
				return false;
      }
    },
    showConnect(){
      this.$store.commit(`common/${MUT_SHOW_CONNECT}`, {
        rcvrUserId: (process.env.NODE_ENV === 'production' ? 7411 : 168),
      });
    },
    getMessageList(isInit, pageNo, pageSize){
      if(isInit) {
        this.items = [];
        this.isLoading = true;
      }

      this.$store.dispatch(`common/${ACT_GET_MESSAGE_LIST}`,{
        pageNo: pageNo ? pageNo : this.pageNo,
        pageSize: pageSize ? pageSize : this.pageSize
      }).then(res=>{
        if(lengthCheck(res)){
          this.items = this.items.concat(getItems(res));
          if(!pageNo && !pageSize) {
            setPaging(this, res);
          }else{
            this.hasMore = getPaging(res).hasMore;
          }
        }else{
          this.items = [];
        }
        this.isLoading = false;
      }).catch(e=>{
        console.error(e);
        this.items = [];
        this.isLoading = false;
      })
    },
    morePage(){
      if(this.hasMore) this.pageNo++;
      this.getMessageList();
    },
    regDtTimeFormat(regDt) {
      return getRegisterTimeFormat(regDt);
    },
    showConnectView(item, idx){
      this.$store.commit(`common/${MUT_SHOW_CONNECT_VIEW}`, {
        msgId: item.msgId,
        userId: item.snderUserId,
        msgCn: item.msgCn,
        userNm: (item.nickNm ? item.nickNm : item.userNm),
        imgPtUrl: item.imgPtUrl,
        regDt: this.regDtTimeFormat(item.regDt),
        readgYn: item.readgYn,
        mngrYn: item.mngrYn,
        userPattnCd: item.userPattnCd,
        readgFunc: () => {
          // 쪽지 읽음 처리
          this.changeReadYn(idx);
        },
        deleteMsgFunc: () => {
          // 쪽지 삭제
          this.changeRemoveYn(idx);
        }
      });
    },
    changeReadYn(idx){
      this.items[idx].readgYn = 'Y';
    },
    changeRemoveYn(){
      // pageNo => 3
      // pageSize => 2
      // pageNo => 1
      // pageSize => 6
      // 현재 페이지

      this.getMessageList(true, 1, (this.pageNo * this.pageSize));


    }
  }
};
</script>