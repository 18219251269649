<template>
  <common-list :div-class="['page-component']"
               :is-loading="isLoading"
               :is-no-result="isNoResult">
    <div class="board-list-container mypage-notice-list">
      <ul class="board-list">
        <li v-for="(item, idx) in items" class="board-list-item" :key="idx">
          <div class="board-list-row">
            <div class="board-list-content">
              <div class="badge">
                <span class="badge-text">정보</span>
              </div>
              <div class="content-title">
                <a href="javascript:" class="title-link" data-anim="true" @click="showModal(item)">
                  <span class="title">{{ item.surveyNm }}</span>
                </a>
              </div>
              <div class="content-date">
                <span class="text">{{ getYmdToFormat(item.surveyBgngYmd) }} ~ {{ getYmdToFormat(item.surveyEndYmd) }}</span>
              </div>
            </div>

            <div v-if="!isMobile " class="board-list-actions">
              <button class="btn btn-primary" type="button" @click="showModal(item)">
                <span class="text">설문하기</span>
              </button>
            </div>

          </div>
        </li>
      </ul>
      <div v-if="hasMore" class="page-buttons" data-align="center">
        <button class="btn btn-lg btn-outline-white" @click="morePage">
          <span class="text">더보기</span>
        </button>
      </div>
    </div>
  </common-list>
</template>

<script>
import {ACT_CHECK_SESSION, ACT_GET_SURVEY_LIST} from '../../store/_act_consts';
import {dateToDateFormat, getItems, lengthCheck, setPaging, ymdToFormat} from '../../assets/js/utils';
import {MUT_SHOW_ALERT, MUT_SHOW_SURVEY} from '../../store/_mut_consts';
import {mapGetters} from 'vuex';
import CommonList from '../common/CommonList';

export default {
  name: 'Survey',
  components: {
    CommonList
  },
  data: () => ({
    isLoading: false,
    hasMore: false,
    totalCount: 0,
    pageSize: 4,
    pageNo: 1,
    items: [],
    currentYmd: '0',
  }),
  computed: {
    ...mapGetters('common', ['isMobile']),
    isNoResult(){
      return this.items.length === 0;
    }
  },
  created() {
    this.$store.dispatch(`auth/${ACT_CHECK_SESSION}`);
    this.currentYmd = dateToDateFormat(new Date(), 'yyyyMMdd');
    this.getSurveyList(true);
  },
  methods: {
    getYmdToFormat(ymd){
      return ymdToFormat(ymd, '.');
    },
    getSurveyList(isInit){
      if(isInit) {
        this.items = [];
        this.isLoading = true;
      }
      this.$store.dispatch(`survey/${ACT_GET_SURVEY_LIST}`, {
        pageNo: this.pageNo,
        pageSize: this.pageSize
      }).then(res => {
        if(lengthCheck(res)){
          this.items = this.items.concat(getItems(res));
          setPaging(this, res);
        }else{
          this.hasMore = false;
        }
        this.isLoading = false;
      }).catch(e => {
        console.error(e);
        this.hasMore = false;
        this.isLoading = false;
      });
    },
    morePage(){
      if(this.hasMore) this.pageNo++;
      this.getSurveyList();
    },
    showModal(item){
      if(item.surveyAnsId > 0){
        this.$store.commit(`common/${MUT_SHOW_ALERT}`, {
          title: '이미 제출한 설문입니다.',
        });
        return;
      }

      if(!this.checkDate(item)){
        this.$store.commit(`common/${MUT_SHOW_ALERT}`, {
          title: '설문기간이 지난 설문입니다.',
        });
        return;
      }

      this.$store.commit(`survey/${MUT_SHOW_SURVEY}`, {
        surveyId: item.surveyId,
        surveyNm: item.surveyNm,
        submitFunc: () => {
          this.getSurveyList(true);
        }
      });
    },
    checkDate(item){
      return item.surveyBgngYmd <= this.currentYmd && item.surveyEndYmd >= this.currentYmd;
    }
  }
};
</script>
