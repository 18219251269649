<template>
  <div class="page-component">
    <common-card
        :items="refineItems"
        :is-tag="true"
        :is-badge="true"
    ></common-card>
  </div>
</template>
<script>
import CommonCard from '../common/CommonCard';
import {ACT_GET_CAMPAIGN_APPLY_LIST} from '../../store/_act_consts';
import {getItems, getRegisterTimeFormat, lengthCheck, setPaging} from '../../assets/js/utils';

export default {
  name: 'CampaignApply',
  components:{
    CommonCard
  },
  data: () => ({
    hasMore: false,
    totalCount: 0,
    pageSize: 12,
    pageNo: 1,
    items: [],
    nowTimestamp: 0
  }),
  computed: {
    refineItems() {
      return this.items.map(x => ({
        ctgr: (x.clsNm || '기타'),
        tit: x.matchCampnNm,
        tags: this.getEntryTimeFormat(x),
        viewLink: `/campaign/${x.matchCampnId}/view`,
        badge: this.getBadgeIcon(x),
        rprsImgPtUrl: x.rprsImgPt
      }));
    }
  },
  created() {
    this.nowTimestamp = new Date().getTime();
    this.getCampaignMyApplyList();
  },
  methods: {
    getCampaignMyApplyList(isInit){
      if(isInit) this.items = [];
      this.$store.dispatch(`promotion/${ACT_GET_CAMPAIGN_APPLY_LIST}`, {
        pageNo: this.pageNo,
        pageSize: this.pageSize
      }).then(res => {
        if(lengthCheck(res)){
          this.items = this.items.concat(getItems(res));
          setPaging(this, res);
        }else{
          setPaging(this, res);
        }
      }).catch(e => {
        console.error(e);
      })
    },
    morePage(){
      if(this.hasMore) this.pageNo++;
      this.getCampaignMyApplyList();
    },
    getEntryTimeFormat(item){
      if(item.entryEndDt < this.nowTimestamp){
        return '신청 마감';
      }else{
        return `마감 ${getRegisterTimeFormat(this.nowTimestamp, item.entryEndDt)}`;
      }
    },
    getBadgeIcon(item){
      if(item.campnAplyStsDcd === '1011002'){
        return 'selection';
      }else if(item.campnAplyStsDcd === '1011001' && this.nowTimestamp > item.matchCampnBgngDt){
        return 'unselection';
      }else if(item.campnAplyStsDcd === '1011001' && this.nowTimestamp < item.matchCampnBgngDt){
        return 'proceeding'
      }
      return null;
    }
  }
}
</script>