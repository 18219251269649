<template>
  <section class="apply-section section-form">
    <header class="section-header">
      <h3 class="title">입점신청</h3>
    </header>
    <!-- round-tabs -->
    <div class="round-tabs">
      <ul class="tab-list">
        <li class="tab-item">
          <a href="javascript:" class="tab-link">
            <span class="tab-text">입점신청</span>
          </a>
        </li>
        <li class="tab-item is-selected">
          <a href="javascript:" class="tab-link">
            <span class="tab-text">신청정보 수정</span>
          </a>
        </li>
      </ul>
    </div>

    <article class="form-section">
      <header class="section-header">
      </header>
      <div class="form-field">
        <div class="form-row">
          <div class="form-cellth is-required">
            <label><span class="text">사업자등록번호</span></label>
          </div>
          <div class="form-celltd">
            <input v-model="bzmnRegNo" class="form-control" type="text" placeholder="사업자등록번호를 입력하세요(0000000000)" maxLength="10" />
          </div>
        </div>
<!--        <form class="form-row" onsubmit="return false;">-->
<!--          <div class="form-cellth is-required">-->
<!--            <label><span class="text">비밀번호</span></label>-->
<!--          </div>-->
<!--          <div class="form-celltd">-->
<!--            <input v-model="scmMngrPasswd" class="form-control" type="password" placeholder="비밀번호를 입력하세요" autocomplete @keyup.enter="clickCheck"/>-->
<!--          </div>-->
<!--        </form>-->
      </div>
    </article>
    <div class="page-buttons flex-center is-wide">
      <loading-button
          button-text="조회하기"
          :is-loading="isLoading"
          :click-func="clickCheck"
          :button-classes="['btn', 'btn-primary']"
          :text-classes="[]"
          loading-size="sm"
      ></loading-button>
    </div>
  </section>
</template>
<script>
import LoadingButton from '../common/LoadingButton';
import {ACT_CHECK_CMSTR_APLY} from '../../store/_act_consts';
import {getItem, isSuccess, lengthCheck} from '../../assets/js/utils';
import {MUT_SHOW_ALERT} from '../../store/_mut_consts';
import {mapGetters} from 'vuex';

export default {
  name: 'StartCheck',
  props: {
    cmstrAprvStsCd: String,
    mutParams: Function
  },
  components: {
    LoadingButton
  },
  data: () => ({
    bzmnRegNo: '',
    // scmMngrPasswd: '',
    isLoading: false
  }),
  computed: {
  },
  methods: {
    clickCheck() {
        this.$store.dispatch(`commerce/${ACT_CHECK_CMSTR_APLY}`, {bzmnRegNo: this.bzmnRegNo})
        .then(res => {
          if(lengthCheck(res)){
            this.mutParams(getItem(res));

            console.log(this.mutParams)
            // if(this.bzmnRegNo )
          }
          // else{
          //   this.$store.commit(`common/${MUT_SHOW_ALERT}`, {title: '사업자등록번호가 일치하지 않습니다.'});
          // }
        });


      // if(!this.bzmnRegNo) {
      //   this.$store.commit(`common/${MUT_SHOW_ALERT}`, {title: '사업자 번호를 입력해주세요.'});
      // }else {
      //   this.$store.dispatch(`commerce/${ACT_CHECK_CMSTR_APLY}`, {bzmnRegNo: this.bzmnRegNo})
      //   .then(res => {
      //     console.log(res);
      //     if(lengthCheck(res)){
      //       this.mutParams(getItem(res));

      //       console.log(this.mutParams)
      //       // if(this.bzmnRegNo )
      //     }else{
      //       this.$store.commit(`common/${MUT_SHOW_ALERT}`, {title: '사업자등록번호가 일치하지 않습니다.'});
      //     }
      //   });
      // }

    }
  },
  created() {
    this.clickCheck();
  },
};
</script>
