<template>
  <common-list :div-class="['page-component']"
               :is-loading="isLoading"
               :is-no-result="isNoResult">
    <ul class="mypage-studio-list">
      <li v-for="(item, idx) in items" class="mypage-studio-item" :key="idx">
        <div class="mypage-studio-card">
          <div class="card-image">
            <router-link :to="`/studio/room/${item.roomId}/${item.studoId}`" class="image-link" >
              <img :src="item.imgPtUrl" alt="">
            </router-link>
<!--            <a href="" class="image-link">-->
<!--            </a>-->
          </div>
          <div class="card-content">
            <h3 class="studio-title">
              <router-link :to="`/studio/room/${item.roomId}/${item.studoId}`" class="title-link" data-anim="true">
<!--              <a href="javascript:" class="title-link" data-anim="true">-->
                <span class="title">{{ getRoomName(item) }}</span>
<!--              </a>-->
              </router-link>
            </h3>
            <p class="studio-text">{{ getReservationDate(item) }}</p>
            <template v-if="isMobile">
              <template v-if="item.rsvtStsDcd === '1002001'">
<!--                <strong  class="status-text">신청완료</strong>-->
                <button class="btn btn-lg btn-primary" @click="clickRoomRsvtCancel(item, idx)"><strong class="text">예약 취소</strong></button>
              </template>
              <button v-else-if="item.rsvtStsDcd === '1002002' && item.rsvtYmd <= currentYmd && item.revwInptYn !== 'Y'" class="btn btn-lg btn-primary"
                      @click="showReview(item, idx)"
              ><strong class="text">후기쓰기</strong></button>
              <strong v-else-if="item.rsvtStsDcd === '1002002'" class="status-text text-primary">승인완료</strong>
              <strong v-else-if="item.rsvtStsDcd === '1002003'" class="status-text text-secondary">반려</strong>
              <strong v-else-if="item.rsvtStsDcd === '1002004'" class="status-text text-secondary">취소완료</strong>
            </template>
          </div>

          <div v-if="!isMobile" class="card-status">
            <template v-if="item.rsvtStsDcd === '1002001'">
<!--                <strong class="status-text">신청완료</strong>-->
                <button class="btn btn-lg btn-primary" @click="clickRoomRsvtCancel(item, idx)"><strong class="text">예약 취소</strong></button>
            </template>
            <button v-else-if="item.rsvtStsDcd === '1002002' && item.rsvtYmd <= currentYmd && item.revwInptYn !== 'Y'" class="btn btn-lg btn-primary"
                    @click="showReview(item, idx)"
            ><strong class="text">후기쓰기</strong></button>
            <strong v-else-if="item.rsvtStsDcd === '1002002'" class="status-text text-primary">승인완료</strong>
            <strong v-else-if="item.rsvtStsDcd === '1002003'" class="status-text text-secondary">반려</strong>
            <strong v-else-if="item.rsvtStsDcd === '1002004'" class="status-text text-secondary">취소완료</strong>
          </div>
        </div>
      </li>
    </ul>

    <div v-if="hasMore" class="page-buttons" data-align="center" @click="morePage">
      <button class="btn btn-lg btn-outline-white">
        <span class="text">더보기</span>
      </button>
    </div>
  </common-list>
</template>

<script>
import {ACT_GET_STUDIO_RESERVATION_LIST, ACT_UPDATE_STUDIO_RESERVATION_CANCEL} from '../../store/_act_consts';
import {dateToDateFormat, getItems, isSuccess, lengthCheck, setPaging, ymdToDateFormat} from '../../assets/js/utils';
import {MUT_SHOW_ALERT, MUT_SHOW_CONFIRM, MUT_SHOW_REVIEW_MODAL, MUT_SET_RETURN_ROUTE} from '../../store/_mut_consts';
import {mapGetters} from 'vuex';
import CommonList from '../common/CommonList';
import {getTokenByKey} from '../../assets/js/jwt.service';

export default {
  name: 'Reservation',
  components: {
    CommonList
  },
  data: () => ({
    isLoading: false,
    hasMore: false,
    totalCount: 0,
    pageSize: 4,
    // pageSize: 1,
    pageNo: 1,
    items: [],
    currentYmd : '0'
  }),
  computed: {
    ...mapGetters('common', ['isMobile']),
    isNoResult(){
      return this.items.length === 0;
    }
  },
  created() {
    this.isLoginCheck();
    this.currentYmd = dateToDateFormat(new Date(), 'yyyyMMdd');
    this.getMyReservationList(true);
  },
  methods: {
    isLoginCheck(){
      if (!getTokenByKey('auth_token')) {
        this.$store.commit(`common/${MUT_SHOW_ALERT}`, {
          title: '회원가입 및 로그인이 필요합니다.',
          html: true,
          yesfunc: () => {
            this.$store.commit(`common/${MUT_SET_RETURN_ROUTE}`, this.$route.path);
            this.$router.push({ name: 'Login' });
          },
        });
				return false;
      }
    },
    getMyReservationList(isInit){
      if(isInit) {
        this.items = [];
        this.isLoading = true;
      }
      this.$store.dispatch(`studio/${ACT_GET_STUDIO_RESERVATION_LIST}`, {
        pageNo: this.pageNo,
        pageSize: this.pageSize
      }).then(res => {
        if(lengthCheck(res)){
          this.items = this.items.concat(getItems(res));
          setPaging(this, res);
        }else{
          setPaging(this, res);
        }
        this.isLoading = false;
      })
      .catch(e => {
        console.error(e);
        this.isLoading = false;
      });
    },
    morePage(){
      if(this.hasMore) this.pageNo++;
      this.getMyReservationList();
    },
    getRoomName(item){
      const result = [];
      if(item.studoNm) result.push(item.studoNm);
      if(item.roomNm) result.push(item.roomNm);
      return result.join(' — ');
    },
    getReservationDate(item){
      const times = [];
      if(item.rsvtBgngHhmm) times.push(`${item.rsvtBgngHhmm.substr(0, 2)}:${item.rsvtBgngHhmm.substr(2, 2)}`);
      if(item.rsvtEndHhmm) times.push(`${item.rsvtEndHhmm.substr(0, 2)}:${item.rsvtEndHhmm.substr(2, 2)}`);
      return `${ymdToDateFormat(item.rsvtYmd, 'yyyy.MM.dd(w)')} ${times.join(' ~ ')}`;
    },
    showReview(item, idx){
      this.$store.commit(`studio/${MUT_SHOW_REVIEW_MODAL}`, {
        roomId: item.roomId,
        rsvtId: item.rsvtId,
        afterFunc: () => {
          this.afterReview(idx);
        }
      });
    },
    clickRoomRsvtCancel(item){

      this.$store.commit(`common/${MUT_SHOW_CONFIRM}`, {
        title: '해당 스튜디오 예약을 취소하시겠습니까?',
        showCancelButton: true,
        yesfunc: ()=> {
          this.updateRommRsvtCancel({rsvtId: item.rsvtId});
        },
        nofunc: () => {}
      });
    },
    updateRommRsvtCancel(rsvtId){
      // 1002004 : 취소
      // '1002004',{rsvtId: item.rsvtId}
      this.$store.dispatch(`studio/${ACT_UPDATE_STUDIO_RESERVATION_CANCEL}`,{
        rsvtStsDcd: '1002004',
        rsvtJson: JSON.stringify(rsvtId)
      }).then(res => {
        if(isSuccess(res)){
          this.$store.commit(`common/${MUT_SHOW_ALERT}`, {
            title: `스튜디오 취소가 완료 되었습니다.`,
            yesfunc: () => {
              this.getMyReservationList(true);
            },
            rightNow: true
          });
        }
      }).catch(e => {
        console.error(e);
      });
    },
    afterReview(idx){
      this.items[idx].revwInptYn = 'Y';
    }
  }
};
</script>
