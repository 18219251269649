<template>
  <common-list :div-class="['page-component']"
               :is-loading="isLoading"
               :is-no-result="isNoResult"
  >
    <div class="myapply-list-container">
      <div class="myapply-header">
        <h3 v-if="proceedingCount > 0" class="title">진행중인 지원사업이 <br><em class="text-secondary">{{ proceedingCount }}건</em> 있습니다.</h3>
      </div>

      <ul class="myapply-list">
        <li v-for="(item, idx) in refineItems" class="myapply-item" :key="idx">
          <article class="myapply-card">
            <div class="myapply-content">
              <p class="department">{{ item.sprtPattn }}</p>
              <h4 class="title">
                <a :href="item.aplyUrl" class="title-link" data-anim="true" target="_blank">
                  {{ item.sprtBizNm }}<i class="icon-link-arrow"></i>
                </a>
              </h4>
              <p v-if="item.chrgDept" class="manager">담당부서 — {{ item.chrgDept }}</p>
            </div>
            <ol class="myapply-proceeding">
              <li class="proceeding-item" :class="{'is-proceeding': item.statusNo === 0}">
                <template v-if="isMobile">
                  <span class="number">01{{item.statusNo === 0 ? ' - 진행중' : ''}}</span>
                  <h4 class="title">서류제출</h4>
                </template>
                <template v-else>
                  <span class="number">01</span>
                  <h4 class="title">서류제출</h4>
                  <span v-if="item.statusNo === 0" class="state">진행중</span>
                </template>
              </li>
              <li class="proceeding-item second" :class="{'is-proceeding': item.statusNo === 1}">
                <template v-if="isMobile">
                  <span class="number">02{{item.statusNo === 1 ? ' - 진행중' : ''}}</span>
                  <h4 class="title">심사 및 평가</h4>
                </template>
                <template v-else>
                  <span class="number">02</span>
                  <h4 class="title">심사 및 평가</h4>
                  <span v-if="item.statusNo === 1" class="state">진행중</span>
                </template>
              </li>
              <li class="proceeding-item" :class="{'is-proceeding': item.statusNo === 2}">
                <span class="number">03</span>
                <h4 class="title">선정‧지원</h4>
              </li>
            </ol>
          </article>
        </li>
      </ul>
      <div v-if="hasMore" class="page-buttons" data-align="center">
        <button class="btn btn-lg btn-outline-white" @click="morePage">
          <span class="text">더보기</span>
        </button>
      </div>
    </div>
  </common-list>
</template>
<script>
import {ACT_CHECK_SESSION, ACT_GET_SUPPORT_APPLY_LIST} from '../../store/_act_consts';
import {dateToDateFormat, getItems, lengthCheck, setPaging} from '../../assets/js/utils';
import {mapGetters} from 'vuex';
import CommonList from '../common/CommonList';

export default {
  name: 'Apply',
  components: {
    CommonList
  },
  data: () => ({
    isLoading: false,
    hasMore: false,
    totalCount: 0,
    pageSize: 3,
    pageNo: 1,
    items: [],
    proceedingCount: 0,
    currentYmd: '0'
  }),
  created() {
    this.$store.dispatch(`auth/${ACT_CHECK_SESSION}`);
    this.currentYmd = dateToDateFormat(new Date(), 'yyyyMMdd');
    this.getSupportApplyList();
  },
  computed: {
    ...mapGetters('common', ['isMobile']),
    refineItems(){
      return this.items.map(x => ({
        sprtBizNm: x.sprtBizNm,
        sprtPattn: x.sprtPattn,
        chrgDept: x.chrgDept,
        aplyUrl: x.aplyUrl,
        statusNo: this.getStatusNo(x)
      }))
    },
    isNoResult(){
      return this.items.length === 0;
    }
  },
  methods: {
    getSupportApplyList(){
      this.isLoading = true;

      this.$store.dispatch(`support/${ACT_GET_SUPPORT_APPLY_LIST}`, {
        pageNo: this.pageNo,
        pageSize: this.pageSize
      }).then(res => {
        if(lengthCheck(res)){
          this.items = this.items.concat(getItems(res));
          setPaging(this, res);
          this.proceedingCount = res.proceedingCount || 0;
        }else{
          this.hasMore = false;
        }
        this.isLoading = false;
      }).catch(e => {
        console.error(e);
        this.hasMore = false;
        this.isLoading = false;
      });
    },
    getStatusNo(item){
      // 선정
      if(item.sprtAplyStsDcd === '1009002'){
        return 2
      }else if(item.sprtAplyStsDcd === '1009001'){
        // 지원 기간내에서 신청한테면 서류제출
        if(item.aplyBgngYmd <= this.currentYmd && item.aplyEndYmd >= this.currentYmd){
          return 0;
        }else{
          return 1;
        }
      }
      return 3;
    },
    morePage(){
      if(this.hasMore) this.pageNo++;
      this.getSupportApplyList();
    },
  }
};
</script>
